import PropTypes from 'prop-types';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Link from '@activebrands/core-web/components/Link';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import Paragraph from 'components/text/Paragraph';

const HighlightCard = ({ background, backgroundOverlay, label, link }) => {
    const [css] = useStyletron();

    return (
        <Link to={link}>
            <div
                className={css({
                    aspectRatio: 'var(--ratio-vertical-secondary)',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    overflow: 'hidden',
                })}
            >
                <BackgroundDynamic
                    $style={{ zIndex: 'var(--zindex-middleground)' }}
                    background={background}
                    backgroundOverlay={backgroundOverlay}
                    loading="eager"
                />
                <div
                    className={css({
                        position: 'absolute',
                        bottom: '12px',
                        left: '12px',
                        zIndex: 'calc(var(--zindex-middleground) + 1)',
                        color: 'var(--color-text-inverted)',
                    })}
                >
                    <Paragraph fontKeys="Primary/24_-015">{label}</Paragraph>
                </div>
            </div>
        </Link>
    );
};

HighlightCard.propTypes = {
    background: PropTypes.any,
    backgroundOverlay: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    label: PropTypes.string,
    link: PropTypes.any,
};

export default HighlightCard;
