import PropTypes from 'prop-types';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import HighlightCard from 'components/HighlightCard';
import PromotionalSlider from 'components/slider/PromotionalSlider';

const Highlight = ({ $style = {}, id, imageSettings, items = [], slidesPerView, tag }) => {
    const [css] = useStyletron();

    const displaySlider = items.length > 5;

    return (
        <div className={css($style)}>
            <PromotionalSlider
                isDraggable
                type="highlight"
                isInfinite={false}
                $paginationStyle={{
                    display: displaySlider ? ['none', null, null, null, 'initial'] : 'none',
                }}
                imageSettings={imageSettings}
                headingFontKeys="Secondary/24_100_-03"
                id={id}
                heading={tag}
                slidesPerView={slidesPerView}
                snapAlign="none"
                spaceBetween="var(--margin-content)"
            >
                {items.map(({ uid, background, backgroundOverlay, label, link }) => (
                    <HighlightCard
                        background={background}
                        backgroundOverlay={backgroundOverlay}
                        key={uid}
                        label={label}
                        link={link?.url}
                    />
                ))}
            </PromotionalSlider>
        </div>
    );
};

Highlight.propTypes = {
    $style: PropTypes.object,
    id: PropTypes.any,
    imageSettings: PropTypes.any,
    items: PropTypes.array,
    slidesPerView: PropTypes.any,
    tag: PropTypes.any,
};

export default Highlight;
