import HighlightDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/HighlightDataTransformerBlock';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

interface HighlightModuleProps {
    isGrid?: boolean;
    content: any;
}

const HighlightModule = ({ isGrid = false, content, ...rest }: HighlightModuleProps) => {
    const [css] = useStyletron();

    return (
        <section
            className={css({
                marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)',
                backgroundColor: 'var(--color-bg)',
            })}
        >
            <div
                className={css({
                    marginLeft: 'auto',
                    marginRight: 'auto',
                })}
            >
                <HighlightDataTransformerBlock content={content} {...rest} />
            </div>
        </section>
    );
};

export default HighlightModule;
